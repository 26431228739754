@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");

:root {
  --styled-font: "Lobster", cursive;
  --styled-font: "Caveat Brush", cursive;

  --text: #000;
  --text-alternate: #fff;
  --logo-color: #fee063;

  --bg: hsl(0, 0%, 100%);
  --glass-bg: hsla(0, 0%, 100%, 0.45);
  --glass-filter: blur(6px) saturate(1.8);
  --glass-border: hsla(0, 0%, 100%, 0.4);
  --glass-shadow: 0px 2px 12px 0px hsla(0, 0%, 0%, 0.1);
}

.loader {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    aspect-ratio: inherit;
    width: 320px;
  }
}
.app {
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  .bg-cover {
    height: 100vh;
    width: 100%;
    inset: 0 0 -1rem auto;
    z-index: -1;

    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(./assets/images/bg.png);

    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      margin-top: -2rem;
      width: min(80rem, 70vw);
      aspect-ratio: 17 / 10;
      background-image: url(./assets/images/logo.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
