.icon-container {
    position: fixed;
    inset: 25% 0 auto auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
        img {
            width: 9rem;
            aspect-ratio: 1;
            transition: all 0.3s ease-in-out;
            filter: saturate(0.5);
            border-radius: 100%;

            &:hover, &:focus {
                filter: saturate(1);
            }
        }
    }
}