.wallet-login-container {
  width: min(45rem, 95vw);

  margin: 5rem auto;
  // padding: 1.5rem 0rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  border-radius: 1rem;
  // border: 4px solid var(--glass-border);
  // backdrop-filter: var(--glass-filter);
  box-shadow: var(--glass-shadow);

  .wallet-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100vw;
    height: 84vh;
    left: 0;

    justify-content: center;
    svg {
      font-size: 8rem;
      animation: loading infinite 1.5s ease-out;
    }
    img {
      aspect-ratio: inherit;
      width: 320px;
    }
    h1 {
      font-size: 2rem;
      text-align: center;
    }
  }

  .metamask-unavailable {
    display: flex;

    align-items: center;
    justify-content: center;
    // gap: 2rem;
    text-align: center;
    background: var(--glass-bg);
    border-radius: 1rem;
    border: 4px solid var(--glass-border);
    backdrop-filter: var(--glass-filter);
    box-shadow: var(--glass-shadow);
    padding: 2rem 4rem;
    svg {
      margin-top: 1.5rem;
      font-size: 8rem;
    }
    h1 {
      font-size: 2rem;
    }
  }

  .metamask-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    h1 {
      color: var(--text);
      font-size: 2rem;
    }

    button {
      height: 10rem;
      aspect-ratio: 16 / 9;

      background-image: url(../../assets/images/connect-btn.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      font-family: "Covered By Your Grace";
      cursor: pointer;
      outline: none;
      border: none;

      padding-top: 0.5rem;
      padding-left: 1rem;

      color: #25474a;
      font-size: 28px;
      font-weight: 600;
      &:hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .wallet-login-container {
    flex-direction: row;
    margin: 1rem auto;
    aspect-ratio: unset;
    min-height: 70vh;
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
