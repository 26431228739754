@mixin stroke($color: #000, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color,
    #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color,
    0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

.mint-pass-screen {
  width: min(30rem, 85vw);
  aspect-ratio: 1;
  margin: 0 auto;
  margin-bottom: 0;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 2rem;

  // background: var(--glass-bg);
  // border-radius: 1rem;
  // border: 4px solid var(--glass-border);
  // backdrop-filter: var(--glass-filter);
  // box-shadow: var(--glass-shadow);

  .mint-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    .mint-button {
      height: 10rem;
      aspect-ratio: 16 / 9;

      background-image: url(../../assets/images/connect-btn.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      font-family: "Covered By Your Grace";
      cursor: pointer;
      outline: none;
      border: none;
      position: absolute;
      bottom: 50px;
      padding-top: 0.5rem;
      padding-left: 1rem;

      color: #25474a;
      font-size: 28px;
      font-weight: 600;
      &:hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: max-content;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem 4rem;
  bottom: 40px;
  align-self: flex-end;

  background: var(--glass-bg);
  border-radius: 1rem;
  border: 4px solid var(--glass-border);
  backdrop-filter: var(--glass-filter);
  box-shadow: var(--glass-shadow);

  cursor: default;

  svg {
    font-size: 4rem;
  }

  .list__message {
    position: absolute;
    top: 40px;
  }
  svg {
    font-size: 4rem;
  }
  h1 {
    font-weight: 600;
    text-align: center;
    color: #886c5e;
    text-shadow: 1px 1px black;
  }
  .messge {
    font-size: 32px;
  }
  .beginTime {
    font-size: 45px;
  }
}

.minted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url(../../assets/images/connect-btn.png);
  background-position: center;
  background-size: cover;

  height: 12rem;
  aspect-ratio: 16 / 9;

  h1 {
    font-size: 2.6rem;
    font-weight: 600;
    color: #254b41;
    font-family: "Covered By Your Grace";

    text-align: center;
    margin-left: 1rem;
    cursor: default;
  }
}

// mobile media query
@media screen and (max-width: 768px) {
  .mint-pass-screen {
    flex-direction: row;
    margin: 1rem auto;
    aspect-ratio: unset;
    min-height: 70vh;

    .pass {
      height: 50vh;
    }

    .mint-container {
      flex: auto;

      .mint-button {
        font-size: 2.6rem;
      }
    }
  }
}

//KEY FRAMES

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
